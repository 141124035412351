<template>
  <div class="error-report-wrapper">
    <div class="error-report-container">
      <div class="top-part">
        <img
          :src="ButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.go(-1)"
        >
        <h4>{{ $t('error-report.category') }}</h4>
      </div>
      <ul class="categories-list">
        <li
          v-for="item in menuItems"
          :key="item.title"
          class="category-item"
        >
          <custom-card
            :creation-data="item"
            @click="$router.push({ name: item.routeName })"
          >
            <template v-slot:cardIcon>
              <img
                :src="item.icon"
                alt="Card icon"
                class="card-icon-image"
              >
            </template>
            {{ item.title }}
          </custom-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonIcon from '@/assets/arrow_button.svg'
import CardIcon1 from '@/assets/icons/Card_icon_1.svg'
import CardIcon2 from '@/assets/icons/Card_icon_2.svg'
import CardIcon3 from '@/assets/icons/Card_icon_3.svg'
import CardIcon4 from '@/assets/icons/Card_icon_4.svg'
const CustomCard = () => import('@/components/GlobalComponents/CustomCard')

export default {
  name: 'ErrorReport',
  components: {
    CustomCard
  },
  data () {
    return {
      ButtonIcon,
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      menuItems: [
        {
          // routeName: 'QuickStartMachine',
          routeName: 'ErrorReportMachine',
          icon: CardIcon1,
          title: this.$t('error-report.machines')
        },
        {
          routeName: 'QuickStartCar',
          icon: CardIcon2,
          title: this.$t('error-report.cars')
        },
        {
          routeName: 'QuickStartIt',
          icon: CardIcon3,
          title: this.$t('error-report.it')
        },
        {
          routeName: 'QuickStartMarketing',
          icon: CardIcon4,
          title: this.$t('error-report.marketing')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  }
}
</script>

<style lang="scss" scoped>
div.error-report-wrapper {
  width: 100%;
  padding-top: 128px; // header + breadcrumbs

  div.error-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.top-part {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 53px;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }
    }

    ul.categories-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      list-style-type: none;
      margin-top: 64px;
      padding: 0;

      li.category-item {
        margin: 0 16px 16px 0;

        .card-icon-image {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-report-wrapper {
    position: relative;
    padding: 64px 0 100px 0;

    div.error-report-container {
      padding: 0;

      div.top-part {
        margin-top: 0;
        padding: 24px;

        img.back-button {
          position: fixed;
          bottom: 100px;
          right: 16px;
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
        }
      }

      ul.categories-list {
        flex-direction: column;
        margin-top: 0;

        li.category-item {
          margin: 0;
          border-bottom: 1px solid $primary-lightgrey;

          .card-icon-image {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}
</style>
